import { createSlice } from '@reduxjs/toolkit'
import { filter } from 'lodash'

// API
import freesbee from 'src/api/freesbee'

// Configs
import { apiPlace, nodeEnv } from 'src/configs/configs'

export const otherSlice = createSlice({
    name: "other",
    initialState: {
        allCuisines: [],
        loading: false,
        menuItems: [],
        cuisines: []
    },
    reducers: {
        setCuisines: (state, action) => {
            state.cuisines = action.payload
            state.loading = false
        },
        setAllCuisines: (state, action) => {
            state.allCuisines = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setMenuIstems: (state, action) => {
            state.menuItems = action.payload
            state.loading = false
        },
        setNewMenuItem: (state, action) => {
            state.menuItems = [action.payload, ...state.menuItems]
            state.loading = false
        },
        setDeletedMenuItem: (state, action) => {
            state.menuItems = filter(state.menuItems, o => o.id !== action.payload)
            state.loading = false
        },
        setUpdateMenuItem: (state, action) => {
            state.menuItems = state.menuItems.map(item => {
                if (item.id === action.payload.id) {
                    return action.payload
                }
                return item
            })
            state.loading = false
        }
    }
})

export const { setCuisines, setAllCuisines, setLoading, setMenuIstems, setNewMenuItem, setDeletedMenuItem, setUpdateMenuItem } = otherSlice.actions

export const addNewMenuItem = (item, callback) => async dispatch => {
    try {
        dispatch(setLoading(true))
        const data = new FormData()
        data.append('name', item.name);
        data.append('price', item.price);
        data.append('category', "featured");
        data.append('description', item.description);

        const response = await freesbee.post(`${apiPlace}/place-items`, data)
        const { place_item } = response.data
        dispatch(setNewMenuItem(place_item))
        callback && callback("Menu item is added", "success")
    } catch (error) {
        dispatch(setLoading(false))
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const editMenuItem = (id, item, callback) => async dispatch => {
    try {
        dispatch(setLoading(true))
        const data = new FormData()
        data.append('name', item.name);
        data.append('price', item.price);
        data.append('category', "featured");
        data.append('description', item.description);

        const response = await freesbee.post(`${apiPlace}/place-items/${id}`, data)
        const { place_item } = response.data
        dispatch(setUpdateMenuItem(place_item))
        callback && callback("Menu item is updated", "success")
    } catch (error) {
        dispatch(setLoading(false))
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const deleteMenuItem = (id, callback) => async dispatch => {
    try {
        dispatch(setLoading(true))
        await freesbee.delete(`${apiPlace}/place-items/${id}`)
        dispatch(setDeletedMenuItem(id))
        callback && callback("Menu item is deleted", "warning")
    } catch (error) {
        dispatch(setLoading(false))
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const getMenuItems = () => async dispatch => {
    try {
        dispatch(setLoading(true))
        const response = await freesbee.get(`${apiPlace}/place-items`)
        const { place_items } = response.data
        dispatch(setMenuIstems(place_items))
    } catch (error) {
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const getMenuItem = (id, callback) => async () => {
    try {
        const response = await freesbee.get(`${apiPlace}/place-items/${id}`)
        const { place_item } = response.data
        callback && callback(place_item)
    } catch (error) {
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const getCuisines = () => async dispatch => {
    try {
        const response = await freesbee.get(`${apiPlace}/cuisines/place`)
        const { cuisines } = response.data
        dispatch(setCuisines(cuisines))
    } catch (error) {
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const getAllCuisines = () => async dispatch => {
    try {
        const response = await freesbee.get(`${apiPlace}/cuisines`)
        const { cuisines } = response.data
        dispatch(setAllCuisines(cuisines))
    } catch (error) {
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const saveCuisines = (add_cuisines, remove_cuisines, callback) => async dispatch => {
    try {
        dispatch(setLoading(true))
        const response = await freesbee.post(`${apiPlace}/cuisines`, { add_cuisines, remove_cuisines })
        const { cuisines } = response.data
        dispatch(setCuisines(cuisines))
        callback && callback()
    } catch (error) {
        dispatch(setLoading(false))
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export default otherSlice.reducer
import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'

// Styles
import './scss/style.scss'

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Forgot = React.lazy(() => import('./views/pages/forgot/Forgot'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const ResetPassword = React.lazy(() => import('./views/pages/reset/ResetPassword'))

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

function App() {
  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login" render={props => <Login {...props} />} />
          <Route exact path="/register" name="Register" render={props => <Register {...props} />} />
          <Route exact path="/forgot-password" name="Forgot Password" render={props => <Forgot {...props} />} />
          <Route exact path="/reset-password" name="Reset Password" render={props => <ResetPassword {...props} />} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route path="/" name="FreesBee" render={props => <TheLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  )
}

export default App

import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoTwo } from './logo-two'
import { logoNegative } from './logo-negative'
import { freesBeeHome } from './sidebar/home'
import { freesBeePeople } from './sidebar/people'
import { freesBeeCalendar } from './sidebar/calendar'
import { freesBeeClock } from './sidebar/clock'
import { freesBeePayment } from './sidebar/payment'
import { freesBeeFlag } from './sidebar/flag'
import { freesBeeStar } from './sidebar/star'
import { freesBeeHelp } from './sidebar/help'
import { freesBeeAsk } from './freesbee/ask'
import { freesBeeDropdown } from './freesbee/dropdown'

import { amex } from './brand/amex'
import { discover } from './brand/discover'
import { visa } from './brand/visa'
import { mastercard } from './brand/mastercard'

import {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex
} from '@coreui/icons'
import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
} from '@coreui/icons'
import {
  cilImage,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilAsterisk,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilBook,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilEyedropper,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGlobeAlt,
  cilGrid,
  cilGraph,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilXCircle,
  cilWarning
} from '@coreui/icons'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoTwo,
  logoNegative,
  freesBeeHome,
  freesBeePeople,
  freesBeeCalendar,
  freesBeeClock,
  freesBeePayment,
  freesBeeFlag,
  freesBeeStar,
  freesBeeHelp,
  freesBeeAsk,
  freesBeeDropdown,
  amex,
  discover,
  visa,
  mastercard
}, {
  cilImage,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilAsterisk,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilBook,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilEyedropper,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGlobeAlt,
  cilGraph,
  cilGrid,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilXCircle,
  cilWarning
}, {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
}, {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex
})

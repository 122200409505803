import { persistStore, persistReducer } from "redux-persist";
import { createStore, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

// Reducers
import businessHoursReducer from "./businesshours/businessHoursSlice";
import dashboardReducer from "./dashboard/dashboardSlice";
import paymentReducer from "./payment/paymentSlice";
import otherReducer from "./other/otherSlice";
import authReducer from "./auth/authSlice";
import menuReducer from "./menu/menuSlice";
import reviewsReducer from "./reviews/reviewSlice";
import invoicesReducer from "./invoices/invoiceSlice";
import customerReducer from "./customers/customerSlice";
import waiterReducer from "./waiters/waiterSlice";

const reducerList = {
  businessHours: businessHoursReducer,
  dashboard: dashboardReducer,
  payment: paymentReducer,
  other: otherReducer,
  menu: menuReducer,
  auth: authReducer,
  review: reviewsReducer,
  invoices: invoicesReducer,
  customers: customerReducer,
  waiter: waiterReducer
};

// Config
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers(reducerList);

const reducers = persistReducer(persistConfig, rootReducer);

// Store
const stores = createStore(reducers);
export const store = configureStore({
  reducer: reducerList,
});

// Resist Store
export const persistor = persistStore(stores);

export const discover = ['513 354.098', `
  <g id="Group_13" data-name="Group 13" transform="translate(-170.5 -638.5)">
    <path id="Path_19" data-name="Path 19" d="M512,402.282a30.266,30.266,0,0,1-30.265,30.267H30.265A30.267,30.267,0,0,1,0,402.282V109.717A30.265,30.265,0,0,1,30.265,79.451H481.734A30.265,30.265,0,0,1,512,109.717V402.282Z" transform="translate(171 559.549)" fill="none" stroke="#707070" stroke-width="1"/>
    <path id="Path_21" data-name="Path 21" d="M309.389,255.8a37.021,37.021,0,0,0-37.46-37.4c-.046,0-.091,0-.139,0a37.6,37.6,0,1,0,37.6,37.6C309.394,255.934,309.389,255.869,309.389,255.8Z" transform="translate(171 559.549)" fill="#f26e21" stroke="#707070" stroke-width="1"/>
    <g id="Group_7" data-name="Group 7" transform="translate(171 559.549)">
      <path id="Path_22" data-name="Path 22" d="M227.2,271.909c-5.62,5.626-10.807,7.824-16.394,7.943-13.611-.122-23.618-10.2-23.618-24.573a27.287,27.287,0,0,1,7.078-18.228h0a22.086,22.086,0,0,1,15.339-6.359c6.507,0,11.571,2.169,17.352,7.954V222.015a36.334,36.334,0,0,0-17.352-4.338,41.889,41.889,0,0,0-24.507,10.2h0A39.375,39.375,0,0,0,172,256.005c0,20.967,16.637,36.875,37.606,36.875.1,0,.2-.009.3-.01.141,0,.28.01.42.01,5.784,0,10.85-1.443,17.357-4.336l-.483-16.635c-.244.244.242.471,0,.7Z" stroke="#707070" stroke-width="1"/>
      <path id="Path_23" data-name="Path 23" d="M356.863,228.033h0L340.487,268.3l-18.8-47.729H306.5L336.148,293.6H344.1L375.2,220.566H360.013Z" stroke="#707070" stroke-width="1"/>
      <path id="Path_24" data-name="Path 24" d="M380.983,252.384v39.051h39.77V279.861H395.445V260.342h24.588v-12.3H395.445V232.861h25.308V220.566h-39.77Z" stroke="#707070" stroke-width="1"/>
      <path id="Path_25" data-name="Path 25" d="M54.135,220.566H33.884v70.869h20.25c10.845,0,18.8-2.895,25.306-7.957A35.756,35.756,0,0,0,92.457,256C92.458,235.028,77.27,220.566,54.135,220.566Zm16.63,53.514c-4.339,3.614-10.124,5.781-18.8,5.781H47.624v-47h3.615c8.678,0,14.463,1.446,18.8,5.783A23.1,23.1,0,0,1,78,256,21.62,21.62,0,0,1,70.765,274.08Z" stroke="#707070" stroke-width="1"/>
      <rect id="Rectangle_1" data-name="Rectangle 1" width="13.739" height="70.867" transform="translate(98.97 220.56)" stroke="#707070" stroke-width="1"/>
      <path id="Path_26" data-name="Path 26" d="M147.415,248.045c-8.676-2.892-10.848-5.063-10.848-8.677,0-4.339,4.339-7.954,10.124-7.954,4.339,0,7.954,1.447,11.57,5.786l7.233-9.4a31.494,31.494,0,0,0-20.97-7.953c-12.3,0-22.42,8.678-22.42,20.244,0,10.126,4.343,14.464,17.357,19.526,5.785,2.166,7.955,2.892,9.4,4.338a7.957,7.957,0,0,1,4.336,7.228c0,5.786-4.336,10.126-10.848,10.126a16.86,16.86,0,0,1-15.187-9.4l-8.678,8.678c6.511,9.4,14.465,13.738,24.589,13.738,14.461,0,24.58-9.4,24.58-23.141C167.659,258.893,163.324,253.831,147.415,248.045Z" stroke="#707070" stroke-width="1"/>
      <path id="Path_27" data-name="Path 27" d="M459.8,261.783c10.843-2.166,16.63-9.4,16.63-20.244,0-13.014-9.4-20.973-25.308-20.973H430.154v70.869h13.739v-28.2h2.172l19.519,28.2h16.634ZM448.23,253.1h-4.336V231.414h4.336c8.678,0,13.742,3.614,13.742,10.85S456.909,253.1,448.23,253.1Z" stroke="#707070" stroke-width="1"/>
    </g>
  </g>
`]

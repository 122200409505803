export const logo = ['158 28', `
    <svg width="158" height="28" viewBox="0 0 158 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.9581 11.3693C16.8248 12.2403 15.8672 13.3159 15.1562 14.5438C14.5213 15.6352 14.0726 16.8551 13.868 18.1407H22.0457C21.8436 16.8525 21.395 15.6352 20.7575 14.5438C20.0491 13.3159 19.0941 12.2403 17.9581 11.3693Z" fill="white"/>
        <path d="M17.9585 28C18.6091 27.5016 19.202 26.9322 19.7267 26.3078H16.1876C16.7149 26.9322 17.3078 27.5016 17.9585 28Z" fill="white"/>
        <path d="M15.0382 24.9147H20.8808C21.1773 24.3821 21.4318 23.8154 21.6259 23.2225H14.2931C14.4872 23.8154 14.7417 24.3821 15.0382 24.9147Z" fill="white"/>
        <path d="M13.8709 21.5276H22.0486C22.1404 20.9767 22.1929 20.41 22.1929 19.8354H13.7266C13.7266 20.41 13.7765 20.9793 13.8709 21.5276Z" fill="white"/>
        <path d="M29.9324 10.2201C26.6267 6.91438 21.2668 6.91438 17.9585 10.2201L23.9454 16.2071C27.2511 19.5127 32.6111 19.5127 35.9194 16.2071L29.9324 10.2201Z" fill="white"/>
        <path d="M5.98704 10.2201L6.10352e-05 16.2071C3.30575 19.5127 8.6657 19.5127 11.974 16.2071L17.961 10.2201C14.6527 6.91438 9.29273 6.91438 5.98704 10.2201Z" fill="white"/>
        <path d="M20.9519 7.22661C22.6048 5.57376 22.6048 2.89248 20.9519 1.23963C19.2991 -0.413211 16.6178 -0.413211 14.9649 1.23963C13.3121 2.89248 13.3121 5.57376 14.9649 7.22661C16.6204 8.88208 19.2991 8.88208 20.9519 7.22661Z" fill="white"/>
        <path d="M48.6752 6.75428H58.7024V3.8841H45.2514V22.3094H48.6752V15.2546H57.7737V12.3608H48.6752V6.75428Z" fill="white"/>
        <path d="M61.8346 10.3041V8.24985H58.7021V22.3069H61.992V15.4883C61.992 14.0847 62.3514 13.009 63.0703 12.2639C63.7892 11.5188 64.7914 11.1463 66.0717 11.1463C66.2999 11.1463 66.5544 11.1725 66.8351 11.225V8.09244C64.4503 8.09244 62.7843 8.82966 61.8346 10.3041Z" fill="white"/>
        <path d="M78.0979 9.00284C77.017 8.39679 75.7944 8.09508 74.4249 8.09508C73.0397 8.09508 71.7935 8.40204 70.6864 9.01595C69.5792 9.62987 68.7161 10.4851 68.0943 11.5818C67.4725 12.6784 67.1603 13.9115 67.1603 15.281C67.1603 16.6663 67.4804 17.9098 68.1205 19.0065C68.7607 20.1031 69.6684 20.9584 70.8438 21.5723C72.0191 22.1862 73.3781 22.4932 74.9234 22.4932C76.1329 22.4932 77.2217 22.2991 78.1871 21.9134C79.1526 21.5277 79.9502 20.9584 80.5825 20.2028L78.8194 18.1748C77.8198 19.1928 76.5474 19.7017 75.0021 19.7017C73.7927 19.7017 72.7773 19.4 71.9614 18.794C71.1455 18.1879 70.6418 17.3694 70.4476 16.3331H81.4771C81.5112 15.9107 81.5296 15.588 81.5296 15.3597C81.5296 13.9194 81.2278 12.6522 80.6218 11.5556C80.0184 10.4589 79.1762 9.60626 78.0979 9.00284ZM70.424 14.1476C70.5814 13.1113 71.0248 12.2823 71.7542 11.6605C72.4835 11.0387 73.3729 10.7265 74.4249 10.7265C75.4954 10.7265 76.39 11.044 77.1115 11.6736C77.8303 12.3059 78.2606 13.1297 78.4023 14.1476H70.424Z" fill="white"/>
        <path d="M93.4014 9.00284C92.3231 8.39679 91.0979 8.09508 89.7284 8.09508C88.3432 8.09508 87.097 8.40204 85.9899 9.01595C84.8827 9.62987 84.0196 10.4851 83.3978 11.5818C82.776 12.6784 82.4638 13.9115 82.4638 15.281C82.4638 16.6663 82.7838 17.9098 83.424 19.0065C84.0641 20.1031 84.9719 20.9584 86.1473 21.5723C87.3226 22.1862 88.6842 22.4932 90.2269 22.4932C91.439 22.4932 92.5251 22.2991 93.4906 21.9134C94.4561 21.5277 95.2537 20.9584 95.8859 20.2028L94.1229 18.1748C93.1233 19.1928 91.8509 19.7017 90.3056 19.7017C89.0962 19.7017 88.0808 19.4 87.2649 18.794C86.449 18.1879 85.9452 17.3694 85.7511 16.3331H96.7806C96.8147 15.9107 96.8331 15.588 96.8331 15.3597C96.8331 13.9194 96.5313 12.6522 95.9253 11.5556C95.3219 10.4589 94.4797 9.60626 93.4014 9.00284ZM85.7275 14.1476C85.8849 13.1113 86.3283 12.2823 87.0576 11.6605C87.787 11.0387 88.6764 10.7265 89.7284 10.7265C90.7988 10.7265 91.6935 11.044 92.4123 11.6736C93.1312 12.3059 93.5615 13.1297 93.7031 14.1476H85.7275Z" fill="white"/>
        <path d="M106.871 14.7274C106.152 14.4992 105.228 14.2972 104.108 14.1214C102.985 13.9272 102.156 13.7226 101.621 13.5022C101.086 13.2845 100.818 12.9093 100.818 12.3846C100.818 11.9097 101.067 11.5293 101.569 11.2407C102.07 10.9521 102.802 10.8052 103.767 10.8052C105.26 10.8052 106.601 11.1568 107.794 11.8573L109.059 9.357C108.445 8.98707 107.655 8.69324 106.69 8.47548C105.724 8.25773 104.759 8.14491 103.793 8.14491C101.899 8.14491 100.393 8.5437 99.2782 9.34388C98.1632 10.1414 97.607 11.2092 97.607 12.542C97.607 13.56 97.8746 14.3549 98.4098 14.9242C98.945 15.4935 99.5773 15.8949 100.304 16.1232C101.033 16.3514 101.975 16.5534 103.135 16.7292C104.239 16.905 105.042 17.0886 105.543 17.2828C106.044 17.4769 106.294 17.818 106.294 18.3086C106.294 19.3449 105.31 19.8618 103.345 19.8618C102.484 19.8618 101.611 19.7358 100.726 19.4813C99.8396 19.2269 99.0814 18.8884 98.4491 18.4687L97.1846 20.9689C97.8352 21.4254 98.7036 21.8032 99.7898 22.0997C100.879 22.3987 101.991 22.5457 103.132 22.5457C105.097 22.5457 106.65 22.1495 107.792 21.3598C108.933 20.5701 109.502 19.5181 109.502 18.201C109.502 17.2015 109.242 16.4301 108.726 15.8844C108.209 15.344 107.59 14.9557 106.871 14.7274Z" fill="white"/>
        <path d="M123.491 12.7257C124.228 12.34 124.808 11.7969 125.228 11.0938C125.65 10.3907 125.86 9.58526 125.86 8.67226C125.86 7.18208 125.272 6.00935 124.097 5.15669C122.922 4.30665 121.245 3.87901 119.07 3.87901H110.436V22.3069H119.598C121.951 22.3069 123.74 21.8714 124.968 21.003C126.196 20.1346 126.81 18.9016 126.81 17.3038C126.81 16.1468 126.511 15.1761 125.915 14.3943C125.314 13.6177 124.509 13.0589 123.491 12.7257ZM113.857 6.56554H118.7C119.894 6.56554 120.81 6.78067 121.45 7.21094C122.09 7.6412 122.41 8.27611 122.41 9.11827C122.41 9.94207 122.09 10.5691 121.45 10.9994C120.81 11.4296 119.891 11.6448 118.7 11.6448H113.857V6.56554ZM119.382 19.623H113.855V14.3051H119.382C122.032 14.3051 123.357 15.1918 123.357 16.9653C123.36 18.7363 122.035 19.623 119.382 19.623Z" fill="white"/>
        <path d="M138.676 9.00284C137.595 8.39679 136.373 8.09508 135.003 8.09508C133.618 8.09508 132.372 8.40204 131.265 9.01595C130.16 9.62987 129.294 10.4851 128.673 11.5818C128.051 12.6784 127.739 13.9115 127.739 15.281C127.739 16.6663 128.059 17.9098 128.699 19.0065C129.339 20.1031 130.247 20.9584 131.422 21.5723C132.597 22.1862 133.959 22.4932 135.502 22.4932C136.714 22.4932 137.8 22.2991 138.766 21.9134C139.731 21.5277 140.529 20.9584 141.161 20.2028L139.398 18.1748C138.398 19.1928 137.126 19.7017 135.581 19.7017C134.368 19.7017 133.356 19.4 132.54 18.794C131.724 18.1879 131.22 17.3694 131.026 16.3331H142.055C142.09 15.9107 142.108 15.588 142.108 15.3597C142.108 13.9194 141.806 12.6522 141.2 11.5556C140.597 10.4589 139.755 9.60626 138.676 9.00284ZM131.002 14.1476C131.16 13.1113 131.603 12.2823 132.333 11.6605C133.062 11.0387 133.951 10.7265 135.006 10.7265C136.076 10.7265 136.971 11.044 137.69 11.6736C138.409 12.3059 138.839 13.1297 138.981 14.1476H131.002Z" fill="white"/>
        <path d="M157.414 15.3597C157.414 13.9194 157.112 12.6522 156.506 11.5556C155.9 10.4589 155.058 9.60888 153.979 9.00284C152.898 8.39679 151.676 8.09508 150.306 8.09508C148.921 8.09508 147.675 8.40204 146.568 9.01595C145.461 9.62987 144.597 10.4851 143.976 11.5818C143.354 12.6784 143.042 13.9115 143.042 15.281C143.042 16.6663 143.362 17.9098 144.002 19.0065C144.642 20.1031 145.55 20.9584 146.725 21.5723C147.901 22.1862 149.26 22.4932 150.805 22.4932C152.017 22.4932 153.103 22.2991 154.069 21.9134C155.034 21.5277 155.832 20.9584 156.464 20.2028L154.701 18.1748C153.701 19.1928 152.429 19.7017 150.884 19.7017C149.674 19.7017 148.659 19.4 147.843 18.794C147.027 18.1879 146.523 17.3694 146.329 16.3331H157.358C157.395 15.9107 157.414 15.588 157.414 15.3597ZM146.305 14.1476C146.463 13.1113 146.906 12.2823 147.636 11.6605C148.365 11.0387 149.254 10.7265 150.309 10.7265C151.379 10.7265 152.274 11.044 152.993 11.6736C153.712 12.3059 154.142 13.1297 154.284 14.1476H146.305Z" fill="white"/>
    </svg>
`]

import { createSlice } from '@reduxjs/toolkit'

export const otherSlice = createSlice({
    name: "other",
    initialState: {
        sidebarShow: 'responsive',
        asideShow: false
    },
    reducers: {
        setAsideShow: (state, action) => {
            state.asideShow = action.payload
        },
        setSideBarShow: (state, action) => {
            state.sidebarShow = action.payload
        },
    }
})

export const { setAsideShow, setSideBarShow } = otherSlice.actions

export default otherSlice.reducer
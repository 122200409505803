import { createSlice } from '@reduxjs/toolkit'

// API
import freesbee from 'src/api/freesbee'

// Configs
import { apiPlace, nodeEnv } from 'src/configs/configs'

export const businessHoursSlice = createSlice({
    name: "businessHours",
    initialState: {
        week: [
            {
                "day": "Monday",
                "day_of_week": 1,
                "opens": null,
                "closes": null,
                "is_closed_all_day": false
            },
            {
                "day": "Tuesday",
                "day_of_week": 2,
                "opens": null,
                "closes": null,
                "is_closed_all_day": false
            },
            {
                "day": "Wednesday",
                "day_of_week": 3,
                "opens": null,
                "closes": null,
                "is_closed_all_day": false
            },
            {
                "day": "Thursday",
                "day_of_week": 4,
                "opens": null,
                "closes": null,
                "is_closed_all_day": false
            },
            {
                "day": "Friday",
                "day_of_week": 5,
                "opens": null,
                "closes": null,
                "is_closed_all_day": false
            },
            {
                "day": "Saturday",
                "day_of_week": 6,
                "opens": null,
                "closes": null,
                "is_closed_all_day": false
            },
            {
                "day": "Sunday",
                "day_of_week": 7,
                "opens": null,
                "closes": null,
                "is_closed_all_day": false
            }
        ],
        loading: false
    },
    reducers: {
        setWeek: (state, action) => {
            state.week = action.payload
            state.loading = false
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    }
})

export const { setWeek, setLoading } = businessHoursSlice.actions

export const getWeekData = () => async dispatch => {
    try {
        dispatch(setLoading(true))
        const response = await freesbee.get(`${apiPlace}/business-hours`)
        const { business_hours } = response.data
        dispatch(setWeek(business_hours))
    } catch (error) {
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const setProcess = (week, callback) => async dispatch => {
    try {
        dispatch(setLoading(true))
        await freesbee.post(`${apiPlace}/business-hours/process`, { week })
        dispatch(setLoading(false))
        callback && callback()
    } catch (error) {
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export default businessHoursSlice.reducer
export const visa = ['513 354.098', `
  <g id="Group_12" data-name="Group 12" transform="translate(-1214.5 -638.5)">
    <path id="Path_47" data-name="Path 47" d="M512,402.282a30.266,30.266,0,0,1-30.265,30.267H30.265A30.267,30.267,0,0,1,0,402.282V109.717A30.265,30.265,0,0,1,30.265,79.451H481.734A30.265,30.265,0,0,1,512,109.717V402.282Z" transform="translate(1215 559.549)" fill="none" stroke="#707070" stroke-width="1"/>
    <g id="visa" transform="translate(1254.009 573.982)">
      <path id="Path_31" data-name="Path 31" d="M184.8,304.52,206.866,180.4h34.478L219.967,304.52Z" transform="translate(-25.513 -0.663)" fill="#3c58bf" stroke="#707070" stroke-width="1"/>
      <path id="Path_32" data-name="Path 32" d="M184.8,304.52,213.072,180.4h28.272L219.967,304.52Z" transform="translate(-25.513 -0.663)" fill="#293688" stroke="#707070" stroke-width="1"/>
      <path id="Path_33" data-name="Path 33" d="M353.5,181.116c-6.9-2.758-17.928-5.516-31.72-5.516-34.478,0-59.3,17.239-59.3,42.063,0,18.618,17.239,28.272,31.03,34.478s17.928,10.343,17.928,15.86c0,8.275-11.033,12.412-20.687,12.412-13.791,0-21.376-2.069-33.1-6.9l-4.827-2.069L248,299.72c8.275,3.448,23.445,6.9,39.3,6.9,36.546,0,60.681-17.239,60.681-43.442,0-14.481-8.964-25.514-29.651-34.478-12.412-6.206-20-9.654-20-15.86,0-5.516,6.206-11.033,20-11.033,11.722,0,20,2.069,26.2,4.827l3.448,1.379,5.516-26.893Z" transform="translate(-34.238 0)" fill="#3c58bf" stroke="#707070" stroke-width="1"/>
      <path id="Path_34" data-name="Path 34" d="M353.5,181.116c-6.9-2.758-17.928-5.516-31.72-5.516-34.478,0-53.1,17.239-53.1,42.063,0,18.618,11.033,28.272,24.824,34.478s17.928,10.343,17.928,15.86c0,8.275-11.033,12.412-20.687,12.412-13.791,0-21.376-2.069-33.1-6.9l-4.827-2.069L248,299.72c8.275,3.448,23.445,6.9,39.3,6.9,36.546,0,60.681-17.239,60.681-43.442,0-14.481-8.964-25.514-29.651-34.478-12.412-6.206-20-9.654-20-15.86,0-5.516,6.206-11.033,20-11.033,11.722,0,20,2.069,26.2,4.827l3.448,1.379,5.516-26.893Z" transform="translate(-34.238 0)" fill="#293688" stroke="#707070" stroke-width="1"/>
      <path id="Path_35" data-name="Path 35" d="M428.045,180.4c-8.275,0-14.481.69-17.928,8.964L358.4,304.52h37.236l6.9-20.687h44.132L450.8,304.52h33.1L454.938,180.4Zm-15.86,82.747c2.069-6.206,13.791-36.546,13.791-36.546s2.758-7.585,4.827-12.412l2.069,11.722s6.9,31.03,8.275,37.926H412.185Z" transform="translate(-49.479 -0.663)" fill="#3c58bf" stroke="#707070" stroke-width="1"/>
      <path id="Path_36" data-name="Path 36" d="M436.32,180.4c-8.275,0-14.481.69-17.928,8.964L358.4,304.52h37.236l6.9-20.687h44.132L450.8,304.52h33.1L454.938,180.4Zm-24.134,82.747c2.758-6.9,13.791-36.546,13.791-36.546s2.758-7.585,4.827-12.412l2.069,11.722s6.9,31.03,8.275,37.926H412.185Z" transform="translate(-49.479 -0.663)" fill="#293688" stroke="#707070" stroke-width="1"/>
      <path id="Path_37" data-name="Path 37" d="M102.917,267.394l-3.448-17.928c-6.206-20.687-26.2-43.442-48.269-54.475L82.23,305.32h37.236L175.32,181.2H138.084Z" transform="translate(-7.068 -0.773)" fill="#3c58bf" stroke="#707070" stroke-width="1"/>
      <path id="Path_38" data-name="Path 38" d="M102.917,267.394l-3.448-17.928c-6.206-20.687-26.2-43.442-48.269-54.475L82.23,305.32h37.236L175.32,181.2H144.98Z" transform="translate(-7.068 -0.773)" fill="#293688" stroke="#707070" stroke-width="1"/>
      <path id="Path_39" data-name="Path 39" d="M0,180.4l6.206,1.379c44.132,10.343,74.472,36.546,86.194,67.576L79.988,190.743c-2.069-8.275-8.275-10.343-15.86-10.343Z" transform="translate(0 -0.663)" fill="#ffbc00" stroke="#707070" stroke-width="1"/>
      <path id="Path_40" data-name="Path 40" d="M0,180.4H0c44.132,10.343,80.678,37.236,92.4,68.266L80.678,199.708a16.832,16.832,0,0,0-16.549-13.1Z" transform="translate(0 -0.663)" fill="#f7981d" stroke="#707070" stroke-width="1"/>
      <path id="Path_41" data-name="Path 41" d="M0,180.4H0c44.132,10.343,80.678,37.236,92.4,68.266l-8.275-26.893c-2.069-8.275-4.827-16.549-14.481-20Z" transform="translate(0 -0.663)" fill="#ed7c00" stroke="#707070" stroke-width="1"/>
      <g id="Group_8" data-name="Group 8" transform="translate(44.821 193.528)">
        <path id="Path_42" data-name="Path 42" d="M137.5,265.356,114.06,241.911l-11.033,26.2-2.758-17.239C94.063,230.188,74.066,207.433,52,196.4L83.03,306.729h37.236Z" transform="translate(-52 -196.4)" fill="#051244" stroke="#707070" stroke-width="1"/>
        <path id="Path_43" data-name="Path 43" d="M219.967,319.54,190.316,289.2,184.8,319.54Z" transform="translate(-70.334 -209.212)" fill="#051244" stroke="#707070" stroke-width="1"/>
        <path id="Path_44" data-name="Path 44" d="M307.991,274.8h0c2.758,2.758,4.137,4.827,3.448,7.585,0,8.275-11.033,12.412-20.687,12.412-13.791,0-21.376-2.069-33.1-6.9l-4.827-2.069L248,314.1c8.275,3.448,23.445,6.9,39.3,6.9,22.066,0,39.994-6.206,50.338-17.239Z" transform="translate(-79.059 -207.224)" fill="#051244" stroke="#707070" stroke-width="1"/>
        <path id="Path_45" data-name="Path 45" d="M364,309.932h32.409l6.9-20.687h44.132l4.137,20.687h33.1L472.95,259.594,431.576,219.6l2.069,11.033s6.9,31.03,8.275,37.926H412.958c2.758-6.9,13.791-36.546,13.791-36.546s2.758-7.585,4.827-12.412" transform="translate(-95.073 -199.603)" fill="#051244" stroke="#707070" stroke-width="1"/>
      </g>
    </g>
  </g>
`]

export const freesBeeClock = ['18 18', `
<g clip-path="url(#clip0)">
<path d="M9 2.39999C7.51664 2.39999 6.0666 2.83986 4.83323 3.66397C3.59986 4.48808 2.63856 5.65942 2.07091 7.02987C1.50325 8.40031 1.35472 9.90831 1.64411 11.3632C1.9335 12.818 2.64781 14.1544 3.6967 15.2033C4.7456 16.2522 6.08197 16.9665 7.53683 17.2559C8.99168 17.5453 10.4997 17.3967 11.8701 16.8291C13.2406 16.2614 14.4119 15.3001 15.236 14.0668C16.0601 12.8334 16.5 11.3834 16.5 9.89999C16.5 8.91508 16.306 7.93981 15.9291 7.02987C15.5522 6.11993 14.9997 5.29313 14.3033 4.59669C13.6069 3.90025 12.7801 3.34781 11.8701 2.9709C10.9602 2.59399 9.98492 2.39999 9 2.39999ZM9 15.9C7.81332 15.9 6.65328 15.5481 5.66658 14.8888C4.67989 14.2295 3.91085 13.2924 3.45673 12.1961C3.0026 11.0997 2.88378 9.89334 3.11529 8.72945C3.3468 7.56557 3.91825 6.49647 4.75736 5.65735C5.59648 4.81824 6.66558 4.24679 7.82946 4.01528C8.99335 3.78377 10.1997 3.90259 11.2961 4.35672C12.3925 4.81084 13.3295 5.57988 13.9888 6.56657C14.6481 7.55327 15 8.71331 15 9.89999C15 11.4913 14.3679 13.0174 13.2426 14.1426C12.1174 15.2679 10.5913 15.9 9 15.9Z"/>
<path d="M12 9.14999H9.75V6.89999C9.75 6.70108 9.67098 6.51032 9.53033 6.36966C9.38968 6.22901 9.19891 6.14999 9 6.14999C8.80109 6.14999 8.61032 6.22901 8.46967 6.36966C8.32902 6.51032 8.25 6.70108 8.25 6.89999V9.89999C8.25 10.0989 8.32902 10.2897 8.46967 10.4303C8.61032 10.571 8.80109 10.65 9 10.65H12C12.1989 10.65 12.3897 10.571 12.5303 10.4303C12.671 10.2897 12.75 10.0989 12.75 9.89999C12.75 9.70108 12.671 9.51032 12.5303 9.36966C12.3897 9.22901 12.1989 9.14999 12 9.14999Z"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="18" height="18" fill="white"/>
</clipPath>
</defs>
`]

import { createSlice } from "@reduxjs/toolkit";

// API
import freesbee from "src/api/freesbee";

// Configs
import { apiPlace, nodeEnv } from "src/configs/configs";

export const customerSlice = createSlice({
  name: "checkins",
  initialState: {
    customer: [],
  },
  reducers: {
    setCustomers: (state, action) => {
      state.customer = action.payload;
    },
  },
});

export const { setCustomers } = customerSlice.actions;

export const getCustomers = (fromDate, toDate) => async (dispatch) => {
  try {
    const startDate = fromDate.format("MM/DD/YYYY");
    const endDate = toDate.format("MM/DD/YYYY");
    const response = await freesbee.post(`${apiPlace}/customers?page=1`, {
      type: "All",
      startDate,
      endDate
    });

    const { checkins } = response.data;
    const { items } = checkins;
    dispatch(setCustomers(items));
  } catch (error) {
    if (nodeEnv === "development") console.log(error.response ? error.response : error);
  }
};

export default customerSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

// API
import freesbee from "src/api/freesbee";

// Configs
import { apiPlace, nodeEnv } from "src/configs/configs";

export const invoiceSlice = createSlice({
  name: "invoices",
  initialState: {
    invoices: [],
  },
  reducers: {
    setInvoices: (state, action) => {
      state.invoices = action.payload;
    },
  },
});

export const { setInvoices } = invoiceSlice.actions;

export const getInvoices = (fromDate, toDate, page) => async (dispatch) => {
  try {
    const startDate = fromDate.format("MM/DD/YYYY");
    const endDate = toDate.format("MM/DD/YYYY");
    const response = await freesbee.get(`${apiPlace}/invoices?page=1`, {
      type: "invoice",
      startDate,
      endDate,
    });
    const { invoices } = response.data;
    const { items } = invoices;
    dispatch(setInvoices(items));
  } catch (error) {
    if (nodeEnv === "development") console.log(error.response ? error.response : error);
  }
};

export default invoiceSlice.reducer;

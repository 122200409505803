export const logoTwo = ['164 29', `
    <svg width="164" height="29" viewBox="0 0 164 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5997 11.7754C17.4258 12.6775 16.434 13.7916 15.6976 15.0633C15.0401 16.1937 14.5754 17.4572 14.3635 18.7887H22.8332C22.6239 17.4545 22.1593 16.1937 21.499 15.0633C20.7653 13.7916 19.7763 12.6775 18.5997 11.7754Z" fill="white"/>
        <path d="M18.5997 29.0002C19.2735 28.4839 19.8876 27.8943 20.4311 27.2476H16.7655C17.3117 27.8943 17.9258 28.4839 18.5997 29.0002Z" fill="white"/>
        <path d="M15.5753 25.8044H21.6266C21.9337 25.2528 22.1973 24.6659 22.3983 24.0518H14.8036C15.0047 24.6659 15.2682 25.2528 15.5753 25.8044Z" fill="white"/>
        <path d="M14.3661 22.2966H22.8358C22.9309 21.726 22.9853 21.139 22.9853 20.5439H14.2167C14.2167 21.139 14.2683 21.7287 14.3661 22.2966Z" fill="white"/>
        <path d="M31.0013 10.5849C27.5775 7.16115 22.0261 7.16115 18.5997 10.5849L24.8005 16.7857C28.2242 20.2095 33.7756 20.2095 37.2021 16.7857L31.0013 10.5849Z" fill="white"/>
        <path d="M6.20086 10.5849L6.10352e-05 16.7857C3.42381 20.2095 8.97518 20.2095 12.4017 16.7857L18.6024 10.5849C15.176 7.16115 9.62461 7.16115 6.20086 10.5849Z" fill="white"/>
        <path d="M21.7 7.4847C23.4119 5.77283 23.4119 2.99578 21.7 1.28391C19.9882 -0.427969 17.2111 -0.427969 15.4992 1.28391C13.7874 2.99578 13.7874 5.77283 15.4992 7.4847C17.2138 9.1993 19.9882 9.1993 21.7 7.4847Z" fill="white"/>
        <path d="M50.4135 6.99564H60.7989V4.02295H46.8675V23.1063H50.4135V15.7996H59.837V12.8024H50.4135V6.99564Z" fill="white"/>
        <path d="M64.0432 10.672V8.54438H60.7987V23.1035H64.2062V16.0413C64.2062 14.5876 64.5784 13.4735 65.323 12.7018C66.0675 11.9301 67.1055 11.5442 68.4315 11.5442C68.6679 11.5442 68.9315 11.5714 69.2223 11.6258V8.38135C66.7523 8.38135 65.0268 9.1449 64.0432 10.672Z" fill="white"/>
        <path d="M80.8875 9.32446C79.768 8.69677 78.5018 8.38428 77.0834 8.38428C75.6487 8.38428 74.358 8.7022 73.2113 9.33804C72.0646 9.97388 71.1706 10.8597 70.5266 11.9955C69.8826 13.1313 69.5593 14.4085 69.5593 15.8269C69.5593 17.2616 69.8908 18.5496 70.5538 19.6854C71.2168 20.8212 72.157 21.707 73.3743 22.3429C74.5916 22.9787 75.9992 23.2966 77.5996 23.2966C78.8523 23.2966 79.98 23.0955 80.9799 22.6961C81.9799 22.2967 82.8059 21.707 83.4608 20.9245L81.6348 18.824C80.5995 19.8783 79.2816 20.4055 77.6812 20.4055C76.4285 20.4055 75.3769 20.093 74.5319 19.4653C73.6868 18.8376 73.1651 17.9898 72.964 16.9165H84.3874C84.4227 16.479 84.4417 16.1448 84.4417 15.9084C84.4417 14.4166 84.1292 13.1042 83.5015 11.9683C82.8766 10.8325 82.0043 9.94943 80.8875 9.32446ZM72.9395 14.653C73.1026 13.5797 73.5618 12.721 74.3172 12.077C75.0726 11.433 75.9937 11.1097 77.0834 11.1097C78.192 11.1097 79.1186 11.4385 79.8659 12.0906C80.6104 12.7455 81.056 13.5987 81.2027 14.653H72.9395Z" fill="white"/>
        <path d="M96.7373 9.32446C95.6205 8.69677 94.3516 8.38428 92.9332 8.38428C91.4984 8.38428 90.2077 8.7022 89.0611 9.33804C87.9144 9.97388 87.0204 10.8597 86.3764 11.9955C85.7324 13.1313 85.4091 14.4085 85.4091 15.8269C85.4091 17.2616 85.7406 18.5496 86.4036 19.6854C87.0666 20.8212 88.0068 21.707 89.2241 22.3429C90.4414 22.9787 91.8517 23.2966 93.4494 23.2966C94.7048 23.2966 95.8298 23.0955 96.8297 22.6961C97.8297 22.2967 98.6557 21.707 99.3106 20.9245L97.4846 18.824C96.4493 19.8783 95.1314 20.4055 93.531 20.4055C92.2783 20.4055 91.2267 20.093 90.3816 19.4653C89.5366 18.8376 89.0149 17.9898 88.8138 16.9165H100.237C100.272 16.479 100.292 16.1448 100.292 15.9084C100.292 14.4166 99.979 13.1042 99.3513 11.9683C98.7264 10.8325 97.8541 9.94943 96.7373 9.32446ZM88.7893 14.653C88.9524 13.5797 89.4116 12.721 90.167 12.077C90.9224 11.433 91.8435 11.1097 92.9332 11.1097C94.0418 11.1097 94.9684 11.4385 95.7129 12.0906C96.4574 12.7455 96.9031 13.5987 97.0498 14.653H88.7893Z" fill="white"/>
        <path d="M110.688 15.2536C109.943 15.0172 108.987 14.808 107.827 14.626C106.664 14.4249 105.805 14.2129 105.251 13.9847C104.696 13.7592 104.419 13.3706 104.419 12.8271C104.419 12.3353 104.677 11.9413 105.196 11.6424C105.715 11.3435 106.473 11.1913 107.473 11.1913C109.019 11.1913 110.408 11.5555 111.644 12.281L112.954 9.69141C112.318 9.30828 111.5 9.00395 110.5 8.77841C109.5 8.55288 108.5 8.43604 107.5 8.43604C105.539 8.43604 103.979 8.84906 102.824 9.67783C101.669 10.5039 101.093 11.6098 101.093 12.9902C101.093 14.0445 101.37 14.8678 101.925 15.4574C102.479 16.0471 103.134 16.4628 103.887 16.6992C104.642 16.9356 105.617 17.1449 106.818 17.3269C107.962 17.509 108.794 17.6992 109.313 17.9003C109.832 18.1013 110.09 18.4546 110.09 18.9627C110.09 20.036 109.071 20.5713 107.036 20.5713C106.145 20.5713 105.24 20.4409 104.324 20.1773C103.406 19.9138 102.62 19.5632 101.965 19.1285L100.656 21.718C101.33 22.1908 102.229 22.5821 103.354 22.8892C104.482 23.1989 105.634 23.3511 106.816 23.3511C108.851 23.3511 110.46 22.9408 111.642 22.1229C112.824 21.305 113.413 20.2154 113.413 18.8513C113.413 17.816 113.144 17.0172 112.609 16.452C112.074 15.8922 111.432 15.4901 110.688 15.2536Z" fill="white"/>
        <path d="M127.902 13.1802C128.665 12.7808 129.266 12.2183 129.7 11.4901C130.138 10.7618 130.355 9.92762 130.355 8.98202C130.355 7.43861 129.747 6.224 128.529 5.34088C127.312 4.46049 125.576 4.01758 123.323 4.01758H114.381V23.1036H123.869C126.307 23.1036 128.16 22.6526 129.431 21.7532C130.703 20.8537 131.339 19.5766 131.339 17.9218C131.339 16.7235 131.029 15.7181 130.412 14.9084C129.79 14.1041 128.956 13.5253 127.902 13.1802ZM117.924 6.80005H122.94C124.176 6.80005 125.125 7.02287 125.788 7.4685C126.451 7.91413 126.782 8.57171 126.782 9.44395C126.782 10.2972 126.451 10.9466 125.788 11.3922C125.125 11.8379 124.174 12.0607 122.94 12.0607H117.924V6.80005ZM123.646 20.3239H117.921V14.816H123.646C126.391 14.816 127.763 15.7344 127.763 17.5713C127.766 19.4054 126.394 20.3239 123.646 20.3239Z" fill="white"/>
        <path d="M143.629 9.32446C142.51 8.69677 141.243 8.38428 139.825 8.38428C138.39 8.38428 137.1 8.7022 135.953 9.33804C134.809 9.97388 133.912 10.8597 133.268 11.9955C132.624 13.1313 132.301 14.4085 132.301 15.8269C132.301 17.2616 132.632 18.5496 133.295 19.6854C133.958 20.8212 134.899 21.707 136.116 22.3429C137.333 22.9787 138.743 23.2966 140.341 23.2966C141.597 23.2966 142.722 23.0955 143.721 22.6961C144.721 22.2967 145.547 21.707 146.202 20.9245L144.376 18.824C143.341 19.8783 142.023 20.4055 140.423 20.4055C139.167 20.4055 138.119 20.093 137.273 19.4653C136.428 18.8376 135.907 17.9898 135.706 16.9165H147.129C147.164 16.479 147.183 16.1448 147.183 15.9084C147.183 14.4166 146.871 13.1042 146.243 11.9683C145.618 10.8325 144.746 9.94943 143.629 9.32446ZM135.681 14.653C135.844 13.5797 136.303 12.721 137.059 12.077C137.814 11.433 138.735 11.1097 139.828 11.1097C140.936 11.1097 141.863 11.4385 142.607 12.0906C143.352 12.7455 143.798 13.5987 143.944 14.653H135.681Z" fill="white"/>
        <path d="M163.036 15.9084C163.036 14.4166 162.723 13.1042 162.096 11.9683C161.468 10.8325 160.596 9.95214 159.479 9.32446C158.359 8.69677 157.093 8.38428 155.675 8.38428C154.24 8.38428 152.949 8.7022 151.803 9.33804C150.656 9.97388 149.762 10.8597 149.118 11.9955C148.474 13.1313 148.151 14.4085 148.151 15.8269C148.151 17.2616 148.482 18.5496 149.145 19.6854C149.808 20.8212 150.748 21.707 151.966 22.3429C153.183 22.9787 154.591 23.2966 156.191 23.2966C157.446 23.2966 158.571 23.0955 159.571 22.6961C160.571 22.2967 161.397 21.707 162.052 20.9245L160.226 18.824C159.191 19.8783 157.873 20.4055 156.273 20.4055C155.02 20.4055 153.968 20.093 153.123 19.4653C152.278 18.8376 151.757 17.9898 151.555 16.9165H162.979C163.017 16.479 163.036 16.1448 163.036 15.9084ZM151.531 14.653C151.694 13.5797 152.153 12.721 152.909 12.077C153.664 11.433 154.585 11.1097 155.678 11.1097C156.786 11.1097 157.713 11.4385 158.457 12.0906C159.202 12.7455 159.647 13.5987 159.794 14.653H151.531Z" fill="white"/>
    </svg>
`]

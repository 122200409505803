export const apiHost = process.env.REACT_APP_API_HOST
export const awsS3Bucket = process.env.REACT_APP_AWS_HOST
export const stripeAPIKey = process.env.REACT_APP_STRIPE_KEY
export const mapboxAPIKey = process.env.REACT_APP_MAPBOX_API_KEY
export const nodeEnv = process.env.NODE_ENV

export const app = "PLACES"
export const role = "ROLE_CUSTOMER"
// APIs
export const apiPublic = "/api"
export const apiSecurity = "/api/security"
export const apiPlace = "/api/place/v1"
export const awsS3PlacePhotos = 'place_photos/'
import { createSlice } from '@reduxjs/toolkit'

// API
import freesbee from 'src/api/freesbee'

// Configs
import { apiPlace, apiSecurity, role, nodeEnv } from 'src/configs/configs'

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        imageLoader: false,
        errorMessage: "",
        loading: false,
        user: null
    },
    reducers: {
        setUser: (state, action) => {
            state.loading = false
            state.user = action.payload
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setImageLoading: (state, action) => {
            state.imageLoader = action.payload
        },
        resetReducer: (state) => {
            state.errorMessage = ""
            state.loading = false
        }
    }
})

export const { setUser, setErrorMessage, setLoading, setImageLoading, resetReducer } = authSlice.actions

export const resetStates = () => dispatch => {
    dispatch(resetReducer())
}

export const login = (email, password, callback) => async dispatch => {
    try {
        dispatch(setLoading(true))
        const response = await freesbee.post(`${apiSecurity}/place-login`, { email, password })
        const { user } = response.data

        localStorage.setItem("LoggedIn", user.api_key)
        localStorage.setItem("email", user.email)
        dispatch(setUser(user))
        callback && callback()
    } catch (error) {
        if (error.response) {
            if (error.response.data) {
                if (error.response.data.error && error.response.data.error.message) {
                    dispatch(setErrorMessage(error.response.data.error.message))
                }
            }
        }
        localStorage.removeItem("LoggedIn")
        localStorage.removeItem("email")
        dispatch(setLoading(false))
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const checkuser = (push) => async (dispatch) => {
    try {
        const apiKey = localStorage.getItem("LoggedIn")
        const email = localStorage.getItem("email")
        const response = await freesbee.post(`${apiSecurity}/user`, { role, email, apiKey })

        const { user } = response.data
        dispatch(setUser(user))
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error.code && error.response.data.error.code === 404) {
                localStorage.clear()
                push('/login')
                dispatch(setUser(null))
            }
        }
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const userUpdate = (params, callback, push) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true))
        const data = new FormData()
        const firstNames = params.name.split(" ")
        firstNames.pop()
        const firstName = firstNames.join(" ")
        const lastName = params.name.split(" ").pop()

        data.append("setFirstName", firstName)
        data.append("setLastName", lastName)
        data.append("setName", params.name)
        data.append("setAddress", params.address)
        data.append("setLongitude", params.addressCenter[0])
        data.append("setLatitude", params.addressCenter[1])
        data.append("setBusinessName", params.businessName)
        data.append("setCountryCode", params.countryCode)
        data.append("setPhone", params.phone)
        data.append("setEmail", params.email)

        const response = await freesbee.post(`${apiPlace}/account`, data)
        const { user } = response.data
        const { auth } = getState()

        if (auth.user.email !== user.email) {
            localStorage.clear()
            push('/login')
            dispatch(setUser(null))
        } else {
            dispatch(setUser(user))
            callback && callback()
        }
    } catch (error) {
        dispatch(setLoading(false))
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const uploadPhoto = (file, callback) => async (dispatch) => {
    try {
        dispatch(setImageLoading(true))
        const data = new FormData()
        data.append('file', file)

        await freesbee.post(`${apiPlace}/account/upload-place-photo`, data)
        dispatch(setImageLoading(false))
        callback && callback("Your photo has been uploaded.")
    } catch (error) {
        dispatch(setImageLoading(false))
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const changePassword = (currentPassword, newPassword, callback) => async dispatch => {
    try {
        dispatch(setLoading(true))
        await freesbee.patch(`${apiPlace}/account/reset-password`, { newPassword, currentPassword })
        dispatch(setLoading(false))
        callback && callback()
    } catch (error) {
        dispatch(setLoading(false))
        if (error.response) {
            if (error.response.data) {
                if (error.response.data.error && error.response.data.error.message) {
                    dispatch(setErrorMessage(error.response.data.error.message))
                    setTimeout(() => {
                        dispatch(setErrorMessage(""))
                    }, 3000)
                }
            }
        }
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const forgotPassword = (email, callback) => async dispatch => {
    try {
        dispatch(setLoading(true))
        const response = await freesbee.post(`${apiSecurity}/reset-password/send`, { email, role })
        dispatch(setLoading(false))
        callback && callback(response.data.message)
    } catch (error) {
        if (error.response) {
            if (error.response.data) {
                if (error.response.data.error && error.response.data.error.message) {
                    dispatch(setErrorMessage(error.response.data.error.message))
                }
            }
        }
        dispatch(setLoading(false))
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const resetPassword = (password, token, callback) => async dispatch => {
    try {
        dispatch(setLoading(true))
        const response = await freesbee.patch(`${apiSecurity}/reset-password`, { token, password, role })
        
        dispatch(setLoading(false))
        callback && callback(response.data.message)
    } catch (error) {
        if (error.response) {
            if (error.response.data) {
                if (error.response.data.error && error.response.data.error.message) {
                    dispatch(setErrorMessage(error.response.data.error.message))
                }
            }
        }
        dispatch(setLoading(false))
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const logOut = (push) => async dispatch => {
    try {
        localStorage.clear()
        push('/login')
        dispatch(setUser(null))
    } catch (error) {
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export default authSlice.reducer
import { createSlice } from "@reduxjs/toolkit"
import { filter } from 'lodash'

// API
import freesbee from "src/api/freesbee"

// Configs
import { apiPlace, nodeEnv } from "src/configs/configs"

export const reviewSlice = createSlice({
    name: "waiter",
    initialState: {
        loading: false,
        waiters: []
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setWaiters: (state, action) => {
            state.waiters = action.payload
        },
        setRemovedWaiter: (state, action) => {
            state.waiters = filter(state.waiters, o => o.id !== action.payload)
        },
        setNewWaiter: (state, action) => {
            state.loading = false
            state.waiters = [...state.waiters, action.payload]
        },
        setUpdatedWaiter: (state, action) => {
            state.loading = false
            state.waiters = state.waiters.map(waiter => {
                if (waiter.id === action.payload.id) {
                    return action.payload
                }
                return waiter
            })
        },
        setStatus: (state, action) => {
            state.waiters = state.waiters.map(waiter => {
                if (waiter.id === action.payload.waiterID) {
                    return {
                        ...waiter,
                        is_active: action.payload.isActive
                    }
                }
                return waiter
            })
        }
    }
})

export const { setWaiters, setStatus, setUpdatedWaiter, setNewWaiter, setRemovedWaiter, setLoading } = reviewSlice.actions

export const getWaiters = () => async dispatch => {
    try {
        const response = await freesbee.get(`${apiPlace}/waiters`)
        const { waiters } = response.data
        dispatch(setWaiters(waiters))
    } catch (error) {
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const addWaiter = (data, callback) => async dispatch => {
    try {
        dispatch(setLoading(true))
        const response = await freesbee.post(`${apiPlace}/waiters`, data)
        const { waiter } = response.data
        dispatch(setNewWaiter(waiter))
        callback && callback("New waiter is added.")
    } catch (error) {
        dispatch(setLoading(false))
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const removeWaiter = (waiterID) => async dispatch => {
    try {
        await freesbee.delete(`${apiPlace}/waiters/${waiterID}`)
        dispatch(setRemovedWaiter(waiterID))
    } catch (error) {
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const editWaiter = (waiterID, data, callback) => async dispatch => {
    try {
        dispatch(setLoading(true))
        const response = await freesbee.patch(`${apiPlace}/waiters/${waiterID}`, data)
        const { waiter } = response.data
        dispatch(setUpdatedWaiter(waiter))
        callback && callback("Waiter information is updated.")
    } catch (error) {
        dispatch(setLoading(false))
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export const toggleStatus = (waiterID) => async dispatch => {
    try {
        const response = await freesbee.get(`${apiPlace}/waiters/${waiterID}/toggle-status`)
        const { is_active } = response.data
        dispatch(setStatus({ isActive: is_active, waiterID }))
    } catch (error) {
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export default reviewSlice.reducer
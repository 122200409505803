import { createSlice } from '@reduxjs/toolkit'

// API
import freesbee from 'src/api/freesbee'

// Configs
import { apiPlace, nodeEnv } from 'src/configs/configs'

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        stats: {
            totalReturningCustomers: 0,
            totalUniqueCustomers: 0,
            totalCustomers: 0
        }
    },
    reducers: {
        setStats: (state, action) => {
            state.stats = action.payload
        }
    }
})

export const { setStats } = dashboardSlice.actions

export const getStats = (fromDate, toDate) => async dispatch => {
    try {
        const startDate = fromDate.format("MM/DD/YYYY")
        const endDate = toDate.format("MM/DD/YYYY")
        const params = { startDate, endDate }
        const response = await freesbee.get(`${apiPlace}/dashboard/stats/checkins`, { params })
        const { totalCustomers, totalUniqueCustomers, totalReturningCustomers } = response.data
        
        const stats = {
            totalReturningCustomers,
            totalUniqueCustomers,
            totalCustomers
        }

        dispatch(setStats(stats))
    } catch (error) {
        if (nodeEnv === "development") console.log(error.response ? error.response : error)
    }
}

export default dashboardSlice.reducer
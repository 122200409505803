import axios from 'axios'
import { apiHost, nodeEnv } from 'src/configs/configs'

const instance = nodeEnv === "development" ? axios.create() : axios.create({ baseURL: apiHost })

instance.interceptors.request.use(
    async config => {
        const isLoggedIn = localStorage.getItem("LoggedIn")
        if (isLoggedIn) {
            config.headers['Access-Control-Allow-Origin'] = '*'
            config.headers["X-AUTH-TOKEN"] = `${isLoggedIn}`
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

export default instance
import { createSlice } from '@reduxjs/toolkit'
import { filter } from 'lodash'

// API
import freesbee from 'src/api/freesbee'

// Configs
import { apiPlace, nodeEnv } from 'src/configs/configs'

export const paymentSlice = createSlice({
    name: "payment",
    initialState: {
        addCouponLoading: false,
        addCardLoading: false,
        couponAmount: 0.00,
        loading: false,
        error: false,
        coupons: [],
        cards: []
    },
    reducers: {
        setCoupons: (state, action) => {
            state.couponAmount = action.payload.amount
            state.coupons = action.payload.coupons
        },
        setNewCoupon: (state, action) => {
            state.coupons = [...state.coupons, action.payload]
            state.setAddCouponLoading = false
        },
        setError: (state, action) => {
            state.setAddCouponLoading = false
            state.addCardLoading = false
            state.error = action.payload
            state.loading = false
        },
        setAddCouponLoading: (state, action) => {
            state.addCouponLoading = action.payload
        },
        setAddCardLoading: (state, action) => {
            state.addCardLoading = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
            state.error = false
        },
        setCard: (state, action) => {
            state.cards = [...state.cards, action.payload]
            state.addCardLoading = false
        },
        setCards: (state, action) => {
            state.cards = action.payload
            state.loading = false
        },
        setDefaultCard: (state, action) => {
            state.loading = false
            state.cards = state.cards.map(card => {
                return { ...card, is_default: card.id === action.payload }
            })
        },
        setDeletedCard: (state, action) => {
            state.cards = filter(state.cards, o => o.id !== action.payload)
            state.loading = false
        }
    }
})

export const {
    setCoupons,
    setNewCoupon,
    setError,
    setLoading,
    setCard,
    setCards,
    setDefaultCard,
    setDeletedCard,
    setAddCouponLoading,
    setAddCardLoading
} = paymentSlice.actions

export const getCoupons = () => async dispatch => {
    try {
        const response = await freesbee.get(`${apiPlace}/coupons`)
        const { coupons } = response.data
        let amount = Number(0.00)

        coupons.forEach(item => {
            amount += Number(item.coupon.amount)
        })

        dispatch(setCoupons({ coupons, amount }))
    } catch (error) {
        if (nodeEnv === "development") console.log((error.response) ? error.response : error)
    }
}

export const addCoupon = (name, callback) => async dispatch => {
    try {
        dispatch(setAddCouponLoading(true))
        const response = await freesbee.post(`${apiPlace}/coupons/apply`, { name })
        const { coupon } = response.data
        dispatch(setNewCoupon({ coupon }))
        callback && callback()
    } catch (error) {
        if (error.response) {
            if (error.response.data) {
                if (error.response.data.error && error.response.data.error.message) {
                    dispatch(setError(error.response.data.error.message))
                    setTimeout(() => dispatch(setError(false)), 4000)
                }
            }
        }
        if (nodeEnv === "development") console.log((error.response) ? error.response : error)
    }
}

export const addCard = (token, data, callback) => async dispatch => {
    try {
        dispatch(setAddCardLoading(true))
        const body = {
            token: token.id,
            card: {
                last4: token.card.last4,
                brand: token.card.brand,
                funding: token.card.funding,
                addressCountry: token.card.country,
                country: token.card.country,
                addressZip: data.zip,
                isApplePayCard: false,
                expMonth: token.card.exp_month,
                expYear: token.card.exp_year,
                firstName: data.firstName,
                lastName: data.lastName
            }
        }
        const response = await freesbee.post(`${apiPlace}/payment/cards`, body)
        const { card } = response.data
        dispatch(setCard(card))
        callback && callback()
    } catch (error) {
        if (error.response) {
            if (error.response.data) {
                if (error.response.data.error && error.response.data.error.message) {
                    dispatch(setError(error.response.data.error.message))
                    setTimeout(() => dispatch(setError(false)), 4000)
                }
            }
        }
        if (nodeEnv === "development") console.log((error.response) ? error.response : error)
    }
}

export const getCards = () => async dispatch => {
    try {
        dispatch(setLoading(true))
        const response = await freesbee.get(`${apiPlace}/payment/cards`)
        const { cards } = response.data
        dispatch(setCards(cards))
    } catch (error) {
        if (nodeEnv === "development") console.log((error.response) ? error.response : error)
    }
}

export const setPrimary = (uuid) => async dispatch => {
    try {
        dispatch(setLoading(true))
        const response = await freesbee.get(`${apiPlace}/payment/cards/${uuid}/set-default`)
        const { card } = response.data
        dispatch(setDefaultCard(card.id))
    } catch (error) {
        dispatch(setLoading(false))
        if (nodeEnv === "development") console.log((error.response) ? error.response : error)
    }
}

export const deleteCard = (uuid, id) => async dispatch => {
    try {
        dispatch(setLoading(true))
        await freesbee.delete(`${apiPlace}/payment/cards/${uuid}`)
        dispatch(setDeletedCard(id))
    } catch (error) {
        dispatch(setLoading(false))
        if (nodeEnv === "development") console.log((error.response) ? error.response : error)
    }
}

export default paymentSlice.reducer
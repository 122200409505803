export const freesBeeFlag = ['18 18', `
<g clip-path="url(#clip0)">
<path d="M15.4525 3.51C15.2813 3.38663 15.0834 3.30536 14.8749 3.27279C14.6664 3.24021 14.4532 3.25724 14.2525 3.3225C13.7231 3.47136 13.1748 3.54211 12.625 3.5325C11.8125 3.4884 11.0157 3.28985 10.2775 2.9475C9.36553 2.52572 8.37906 2.28867 7.37501 2.25C5.20751 2.25 4.37501 3 4.22501 3.105C4.15324 3.17534 4.09634 3.25939 4.05769 3.35216C4.01904 3.44492 3.99943 3.54451 4.00001 3.645V15C4.00001 15.1989 4.07903 15.3897 4.21968 15.5303C4.36034 15.671 4.5511 15.75 4.75001 15.75C4.94893 15.75 5.13969 15.671 5.28034 15.5303C5.42099 15.3897 5.50001 15.1989 5.50001 15V11.775C6.09763 11.5462 6.73561 11.4416 7.37501 11.4675C8.18757 11.5116 8.98428 11.7101 9.72251 12.0525C10.6345 12.4743 11.621 12.7113 12.625 12.75C13.5291 12.7841 14.4286 12.6043 15.25 12.225C15.4714 12.121 15.6591 11.9569 15.7917 11.7513C15.9243 11.5458 15.9965 11.3071 16 11.0625V4.5825C15.9997 4.37323 15.95 4.16699 15.8549 3.9806C15.7597 3.79421 15.6218 3.63296 15.4525 3.51ZM14.5 10.9425C13.9022 11.1705 13.2644 11.2751 12.625 11.25C11.8115 11.2069 11.0141 11.0056 10.2775 10.6575C9.36379 10.2418 8.37805 10.0075 7.37501 9.9675C6.74314 9.95661 6.11262 10.0298 5.50001 10.185V4.065C6.09624 3.8305 6.73489 3.72321 7.37501 3.75C8.18858 3.79311 8.98594 3.99436 9.72251 4.3425C10.6362 4.75819 11.622 4.99252 12.625 5.0325C13.2573 5.04184 13.8879 4.96616 14.5 4.8075V10.9425Z"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="18" height="18" fill="white"/>
</clipPath>
</defs>
`]
